// @import url('https://fonts.googleapis.com/css?family=Lato:400,500,600,700,800,900&display=swap');
// @import url('https://fonts.googleapis.com/css?family=IBM Plex Sans:400,500,600,700,900&display=swap');

@font-face {
  font-display: swap;
  font-family: GCentra;
  font-style: normal;
  font-weight: 500;
  src: url(./static/fonts/gcentra-book-ebf32d29.woff2) format("woff2"),
    url(./static/fonts/gcentra-book-2607bc81.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: GCentra;
  font-style: normal;
  font-weight: 600;
  src: url(./static/fonts/gcentra-medium-cb005fbb.woff2) format("woff2"),
    url(./static/fonts/gcentra-medium-81291f0b.woff) format("woff")
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
  color: #000000d9;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.015rem;
  font-family: GCentra,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum', 'tnum';
}

.App {
  /* text-align: center; */
  min-height: 100vh;
  display: flex;
}

.app-canvas {
  flex-direction: column;
  padding: 30px 3vw;
  display: flex;
  /* justify-content: center; */
  background: #fff;
  min-height: 280px;
}

.centered-spinner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.site-layout {
   transition: margin-left 0.2s
}

.has-sidebar {
  .site-layout.sidebar-collapsed {
    margin-left: 80px;
  }

  .site-layout.sidebar-expanded {
    margin-left: 230px;
  }
}

.full-screen {
  .site-layout.sidebar-collapsed,
  .site-layout.sidebar-expanded {
    margin-left: 0;
  }
}



