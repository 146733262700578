.settings-menuform {
  display: flex;
}

.settings-form {
  margin-left: 40px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.settings-form-input {
  width: 250px !important;
}

.single-form {
  padding: 24px !important;
  /* background: #fbfbfb; */
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin: 20px 0 !important;
  width: 550px;
}

.plan-details-since {
  text-align: right;
  width: 100%;
  display: block;
}

.billing-list {
  margin: 0 0 20px 0 !important;
}

.billing-list li {
  padding: 5px 0 !important;
}

.billing-list li span.billing-list-item-title {
  font-weight: 600;
  width: 110px;
  display: inline-block;
}
