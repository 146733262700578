.full-screen .sidebar-container {
  display: none
}

.sidebar-container {
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);

  .ant-menu {
    border: none !important;

    &.sidebar-menu-logo {
      .ant-menu-item-active {
        background-color: initial !important;
      }
    }

    &.sidebar-menu-footer {
      .ant-menu-item {
        padding-left: 24px;
      }
    }
  }
}