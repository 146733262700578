.dashboard {
  &-resource-card {
    cursor: pointer;

    &:hover {
      background-color: rgba(244, 93, 72, 0.1)
    }

    &-emoji {
      display: inline-block;
      margin-right: 5px;
      font-size: 15px;
    }
  }
}