.prospect-form-item {
  margin-bottom: 5px;

  .ant-form-item-label {
    padding-bottom: 0;

    label {
        font-size: 13px !important;
        text-transform: uppercase;
        font-weight: 600;
      }
  }


  &__text {
    font-size: 13px !important;

    .ant-input {
        margin-left: 10px !important;
        margin-top: 2px !important;
        padding: 0px 7px !important;
        border-radius: 4px !important;
        // height: 10px !important;
        min-height: initial !important;
      }
  }
}