.dashboard-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &-header {
    display: flex;
    align-items: baseline;

    &-index {
        align-items: center;
        background-color: rgba(100, 201, 246, 0.1);
        border-radius: 50%;
        color: rgb(100, 201, 246);
        display: flex;
        height: 25px;
        justify-content: center;
        width: 25px;
        margin-right: 15px;
      }
  }

}