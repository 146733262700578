.signup-form {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signup-form .signup-card {
  width: 450px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 25px 50px -10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
}

.login-form-item {
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px !important;
  justify-content: space-between;
}

.login-form-item label {
  float: left;
  margin-bottom: 0 !important;
  line-height: 25px;
  font-size: 14px !important;
}

.login-form-item-actions {
  flex-direction: column;
  min-width: 400px;
}

.login-form-input {
  /* width: 300px */
}
