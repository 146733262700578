.prospect-list-item {
  cursor: pointer;
  padding: 10px !important;
}

.prospect-list-item:hover:not(.prospect-list-item-selected) {
  background-color: #f0f0f042;
  border-radius: 0px;
}

.prospect-list-item-selected {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.prospect-list-item-meta .ant-list-item-meta-avatar {
  align-self: center;
}


.prospect-list-item-meta .ant-avatar {
  box-shadow: 0 0 0 2px rgba(198, 198, 198, 0.2)
}

.prospect-list-item-meta .ant-avatar > img {
  object-fit: contain;
}