.prospects-table-row {
  cursor: pointer;
}

.prospects-table-email {
    font-size: 12px;
  /* width: 200px; */
  /* height: 45px; */
  /* display: flex !important; */
  /* align-items: center; */
}

.prospects-table-detail-column {
  font-size: 12px;

  &-icebreaker {
    width: 150px;
  }
}
