.sequence-step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sequence-step-follow-up {
    /* margin: 40px 0; */
}

.sequence-step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px;
}

.sequence-step-wait-container {
    border: 1px solid #d9d9d9;
    display: flex;
    width: 100%;
}

.sequence-step-wait-icon-container {
    width: 80px;
    background: aliceblue;
    border-right: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sequence-step-wait {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
}