.full-screen .header-container {
  display: none
}

.header-container {
  background: #fff;
  padding: 0 20px;
  height: 56px;
  border-width: 0;
  border-bottom-width: 0px;
  border-bottom: 1px rgb(225, 225, 225);
  border-style: solid;
}
